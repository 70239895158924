<template>
  <div class="add-pos-contaner">
    <Header></Header>
    <Nav></Nav>
    <Layout>
      <el-row class="pb16" type="flex" align="middle" justify="space-between">
        <div class="left f22 c3">新增地址</div>
        <div class="right">
          <button @click="toDevice">
            <!-- <img src="@/assets/images/home/return.png" alt="" />
            <span class="f14 c6">返回</span> -->
            <img class="back-img" src="@/assets/images/back-img.png" alt="" />
          </button>
        </div>
      </el-row>
      <div class="line"></div>
      <!-- 表单 s -->
      <el-form
        label-width="122px"
        :model="ruleForm"
        :status-icon="false"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        :hide-required-asterisk="true"
      >
        <!-- 联系人： -->
        <el-form-item label="联系人:" required prop="person">
          <el-input
            type="text"
            maxlength="10"
            v-model="ruleForm.person"
            placeholder="请输入联系人"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- 联系人： -->
        <!-- 性别 -->
        <el-form-item label="性别:" required prop="sex">
          <div class="item" @click="changeSex('男')">
            <img
              v-if="ruleForm.sex == '男'"
              src="@/assets/images/home/correct.png"
              alt=""
            />
            <img
              src="@/assets/images/home/no-correct.png"
              v-if="ruleForm.sex != '男'"
              alt=""
            />
            <span>男</span>
          </div>
          <div class="item item2" @click="changeSex('女')">
            <img
              v-if="ruleForm.sex == '女'"
              src="@/assets/images/home/correct.png"
              alt=""
            />
            <img
              src="@/assets/images/home/no-correct.png"
              v-if="ruleForm.sex != '女'"
              alt=""
            />
            <span>女</span>
          </div>
        </el-form-item>
        <!-- 性别end -->
        <!-- 联系电话： -->
        <el-form-item label="联系电话:" required prop="phone">
          <el-input
            type="phone"
            maxlength="11"
            v-model="ruleForm.phone"
            placeholder="请输入联系电话"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- 联系电话： -->
        <!-- 所在地区 -->
        <el-form-item
          class="mb20"
          label="所在地区:"
          required
          prop="provinceCode"
        >
          <el-row class="check" type="flex" align="middle">
            <!-- <el-select v-model="ruleForm.area" placeholder="请选择所在地区">
              <el-option
                v-for="item in areaData"
                :key="item.value"
                :value="item.value"
              >
                <span style="float: left; color: #8492a6; font-size: 13px">{{
                  item.value
                }}</span>
              </el-option>
            </el-select> -->
            <el-cascader
              :options="options"
              :props="props"
              @change="posHandle"
            ></el-cascader>
          </el-row>
        </el-form-item>
        <!-- 所在地区 -->
        <!-- 详细地址: -->
        <el-form-item class="mb20" label="地址详情:" required prop="address">
          <el-input
            type="textarea"
            v-model="ruleForm.address"
            placeholder="请输入地址详情"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- 详细地址:结束 -->
        <!-- 详细地址: -->
        <el-form-item class="mb20" required prop="set">
          <span class="f14 c3 set">设置为默认收货地址:</span>
          <el-switch v-model="ruleForm.set" active-color="#4DE877"> </el-switch>
        </el-form-item>
        <!-- 详细地址:结束 -->
        <!-- 提交开始 -->
        <el-form-item>
          <el-button class="btn cf f16" @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
        <!-- 提交结束 -->
      </el-form>
      <!-- 表单 e -->
    </Layout>
    <Footer></Footer>
  </div>
</template>

<script>
import Layout from "@/components/Layout.vue";
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
export default {
  name: "YoufujichuangAddPos",
  components: {
    Layout,
    Header,
    Nav,
    Footer,
  },
  data() {
    var validateId = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入联系人"));
      }
      callback();
    };
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入联系电话"));
      }
      callback();
    };
    var validateArea = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入地区"));
      }
      callback();
    };
    var validateAddress = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入详细地址"));
      }
      callback();
    };
    var validateSex = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择性别"));
      }
      callback();
    };
    return {
      ruleForm: {
        person: "",
        sex: "男",
        phone: "",
        area: "",
        address: "",
        set: true,
        provinceCode: "", //省code
        cityCode: "", //市code
        areaCode: "", //区code
      },
      rules: {
        person: [{ validator: validateId, trigger: "blur" }],
        sex: [{ validator: validateSex, trigger: "blur" }],
        phone: [{ validator: validatePhone, trigger: "blur" }],
        // area: [{ validator: validateArea, trigger: "change" }],
        address: [{ validator: validateAddress, trigger: "blur" }],
        provinceCode: [
          { required: true, message: "请选择地区", trigger: "change" },
        ],
      },
      areaData: [
        {
          value: "北京",
        },
        {
          value: "上海",
        },
      ], //设备所有类型
      flag: true, //防止重复点击

      // 地区选择器
      props: { value: "id", label: "fullname", children: "areaTrees" },
      options: [],
    };
  },

  mounted() {},

  created() {
    //查询省市区
    this.getArea();
  },

  methods: {
    // 省市区改变
    posHandle(a) {
      this.ruleForm.provinceCode = a[0];
      this.ruleForm.cityCode = a[1];
      this.ruleForm.areaCode = a[2];
    },

    // 提交
    submitForm(formName) {
      if (!this.flag) {
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.flag = false;
          //处理性别
          let sex;
          if (this.ruleForm.sex == "男") {
            sex = 1;
          } else {
            sex = 2;
          }
          // 选中省市区对应名称的获取
          let selectProvince = {};
          let selectCity = {};
          let selectArea = {};
          this.options.forEach((item) => {
            if (item.id == this.ruleForm.provinceCode) {
              selectProvince = item;
            }
          });
          selectProvince.areaTrees.forEach((item) => {
            if (item.id == this.ruleForm.cityCode) {
              selectCity = item;
            }
          });
          selectCity.areaTrees.forEach((item) => {
            if (item.id == this.ruleForm.areaCode) {
              selectArea = item;
            }
          });
          //处理默认地址
          let isDefault = this.ruleForm.set ? 1 : 0;
          let params = {
            contactsName: this.ruleForm.person, //联系人
            sex, //性别
            contactsPhone: this.ruleForm.phone, //联系电话
            address: this.ruleForm.address, //详细地址
            isDefault, //是否默认
            provinceCode: this.ruleForm.provinceCode,
            cityCode: this.ruleForm.cityCode,
            areaCode: this.ruleForm.areaCode,
            provinceName: selectProvince.fullname,
            cityName: selectCity.fullname,
            areaName: selectArea.fullname,
          };
          this.$API
            .addPos(params)
            .then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: "新增成功",
                  type: "success",
                  duration: 600,
                });
                setTimeout(() => {
                  this.$router.back();
                }, 800);
                this.goodList = res.data.records;
              } else {
                this.$message.info(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          // this.$router.push("/contact/address");
          // alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 返回
    toDevice() {
      this.$router.push("/contact/address");
    },
    // 改变性别
    changeSex(sex) {
      this.ruleForm.sex = sex;
    },
    //查询省市区
    getArea() {
      this.$API
        .getArea()
        .then((res) => {
          if (res.code == 1) {
            // this.options = res.data;
            this.options = res.data.map((item) => {
              // 第一级，市
              // item.areaTrees 第二级，区
              item.areaTrees = item.areaTrees.map((secondItem) => {
                // secondItem
                secondItem.areaTrees = secondItem.areaTrees.map((thirdItem) => {
                  delete thirdItem.areaTrees;
                  return thirdItem;
                });
                return secondItem;
              });

              return item;
            });
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
.add-pos-contaner {
  .right {
    // button {
    //   width: 96px;
    //   height: 40px;
    //   background: #efefef;
    //   border-radius: 4px 4px 4px 4px;
    //   img {
    //     width: 16px;
    //     height: 16px;
    //     margin-right: 5px;
    //     vertical-align: top;
    //   }
    // }
    .back-img {
      width: 72px;
      height: 24px;
    }
  }
  // 输入框
  .demo-ruleForm {
    margin-top: 32px;
    // 性別
    .item {
      display: inline-block;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .item2 {
      margin-left: 56px;
    }
    .set {
      position: relative;
      left: -77px;
    }
    .btn {
      width: 214px;
      height: 50px;
      background: linear-gradient(45deg, #ed1b23 0%, #f15f64 100%);
      border-radius: 8px 8px 8px 8px;
      color: #ffffff;
      margin-top: 173px;
      margin-left: 393px;
    }
  }
  .el-switch__core {
    position: relative;
    left: -37px;
  }
  .el-form-item {
    margin-bottom: 32px;
  }
  .el-input__inner {
    width: 320px;
    height: 40px;
  }
  .el-form-item__label {
    text-align: right;
  }
  .el-textarea__inner {
    width: 400px;
    height: 120px;
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
  }
}
</style>